<!-- 下载专区 -->
<template>
  <LandCard title="资料文档" :isLeftMore="true">
    <template v-slot:topRight>
      <span class="more" @click="handleClickMore">更多 ></span>
    </template>
    <div class="contentBody">
      <div class="articleBody" v-for="(item, i) in dataDowload" :key="i">
        <div class="articleTitle onelinestyle" @click="showNewsDetail(item)">
          <div class="dot"></div>
          {{ item.title }}
        </div>
        <div class="articleDate">
          {{ item.date }}
        </div>
      </div>
    </div>
  </LandCard>
</template>

<script>
import { mapGetters } from "vuex";
import LandCard from "@/components/Card/landTrustCard.vue";
import { getFileLists } from "@/api/download.js";
export default {
  components: {
    LandCard,
  },
  computed: {
    ...mapGetters(["topSelectAreacode"]),
  },
  watch: {
    topSelectAreacode: function () {
      this.init();
    },
  },
  data() {
    return {
      dataDowload: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getInfo();
    },
    async getInfo() {
     let params = {
        current: 1,
        size: 6,
        catalog: "0103",
        areaCode: this.topSelectAreacode,
      };
      let res = await getFileLists(params);
      if (!res || res.code != 0) {
        return;
      }
      this.dataDowload = res.data.records;
      this.dataDowload.forEach((item) => {
        item.date = item.createTime.substr(0, 10);
      });
    },

    downloadUrlFile(url, fileName) {
      const url2 = url.replace(/\\/g, "/");
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url2, true);
      xhr.responseType = "blob";
      // 为了避免大文件影响用户体验，建议加loading
      xhr.onload = () => {
        if (xhr.status === 200) {
          // 获取文件blob数据并保存
          saveAs(xhr.response, fileName);
        }
      };
      xhr.send();
    },
    saveAs(data, name) {
      const urlObject = window.URL || window.webkitURL || window;
      const export_blob = new Blob([data]);
      //createElementNS() 方法可创建带有指定命名空间的元素节点。
      //此方法可返回一个 Element 对象。
      const save_link = document.createElement("a"); //创建a标签
      save_link.href = urlObject.createObjectURL(export_blob);
      save_link.download = name;
      save_link.click();
      window.URL.revokeObjectURL(urlObject);
    },
    handleClickMore() {
      this.$router.push({ name: "download", query: { index: 2 } });
    },
    showNewsDetail(article) {
      // 新闻详情
      this.$router.push({ name: "newsPage", query: { c: article.articleId } });
    },
  },
};
</script>
<style lang="less" scoped>
.contentBody {
  height: calc(100% - 50px);
  padding: 2px 25px;
  .articleBody {
    display: grid;
    grid-template-columns: 55% 1fr;
    gap: 24px;
    margin-top: 17px;
    align-items: center;
    cursor: pointer;
    .articleTitle {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;

      .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #0668b3;
        border-radius: 50%;
        margin-right: 7px;
      }
    }

    .articleDate {
      flex: 1;
      text-align: right;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }

    .articleBtn {
      width: 48px;
      height: 19px;
      background: #67c23a;
      border-radius: 4px;
      border: 1px solid #67c23a;
      cursor: pointer;

      text-align: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      color: #ffffff;
    }
    .onelinestyle {
      background-color: transparent;

      /*1. 超出的部分隐藏 */
      overflow: hidden;
      /*2. 文字用省略号替代超出的部分 */
      text-overflow: ellipsis;
      /* 3. 弹性伸缩盒子模型显示 */
      display: -webkit-box;
      /* 4. 限制在一个块元素显示的文本的行数 */
      -webkit-line-clamp: 1;
      /* 5. 设置或检索伸缩盒对象的子元素的排列方式 */
      -webkit-box-orient: vertical;

      span {
        background-color: transparent !important;
        color: #dddddd !important;
      }
    }
  }
}
.more {
  flex: 1;
  text-align: right;
  padding-right: 15px;
  color: #666;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    color: #0668b3;
  }
}
</style>
