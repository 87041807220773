<template>
  <card title="放款机构排行">
    <div class="transaction-flow">
      <div class="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <FSTopLeft></FSTopLeft>
          </el-col>
          <el-col :span="12">
            <FSTopRight></FSTopRight>
          </el-col>
        </el-row>
      </div>
      <div class="bottom">
        <el-row :gutter="20">
          <el-col :span="8">
            <bottom-left></bottom-left>
          </el-col>
          <el-col :span="8">
            <bottom-center></bottom-center>
          </el-col>
          <el-col :span="8">
            <bottom-right></bottom-right>
          </el-col>
        </el-row>
      </div>
    </div>
  </card>
</template>

<script>
import Card from "@/components/Card/index.vue";
import FSTopLeft from './FSTopLeft.vue';
import FSTopRight from './FSTopRight.vue';
import bottomLeft from '../../productionService/components/landTrustSubpage/bottomLeft.vue';
import bottomCenter from '../../productionService/components/landTrustSubpage/bottomCenter.vue';
import bottomRight from '../../productionService/components/landTrustSubpage/bottomRight.vue';

export default {
  components: {
    Card,
    FSTopRight,
    FSTopLeft,

    bottomLeft,
    bottomCenter,
    bottomRight
  },
};
</script>

<style lang="less">
.transaction-flow {
  .top {
    height: 376px;
    margin-bottom: 15px;
  }

  .bottom {
    height: 332px;
  }

  .el-row, .el-col {
    height: 100%;
  }
}
</style>
