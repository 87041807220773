<!-- 政策法规 -->
<template>
  <LandCard title="法律法规" :isLeftMore="true">
    <template v-slot:topRight>
      <span class="more" @click="handleClickMore">更多 ></span>
    </template>
    <div class="contentBody">
      <div
        class="articleBody"
        v-for="(item, i) of dataPoliciesRegulations"
        :key="i"
        @click="showNewsDetail(item)"
      >
        <div class="articleTitle onelinestyle">
          <div class="dot"></div>
          {{ item.title }}
        </div>
        <div class="articleDate">{{ item.date }}</div>
      </div>
    </div>
  </LandCard>
</template>

<script>
import { mapGetters } from "vuex";
import LandCard from "@/components/Card/landTrustCard.vue";
import { getFileLists } from "@/api/download.js";
export default {
  components: {
    LandCard,
  },
  computed: {
    ...mapGetters(["topSelectAreacode"]),
  },
  watch: {
    topSelectAreacode: function () {
      this.init();
    },
  },
  data() {
    return {

      dataPoliciesRegulations: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getInfo();
    },
    async getInfo() {
        let params= {
        current: 1,
        size: 6,
        catalog: "0101",
        areaCode: this.topSelectAreacode,
      };
      let res = await getFileLists(params);
      if (!res || res.code != 0) {
        return;
      }
      this.dataPoliciesRegulations = res.data.records;
      this.dataPoliciesRegulations.forEach((item) => {
        item.date = item.createTime.substr(0, 10);
      });
    },
    handleClickMore() {
      this.$router.push({ name: "download", query: { index: 0 } });
    },
    showNewsDetail(article) {
      // 新闻详情
      this.$router.push({ name: "newsPage", query: { c: article.articleId } });
    },
  },
};
</script>
<style lang="less" scoped>
.contentBody {
  height: calc(100% - 50px);
  padding: 2px 25px;
  .articleBody {
    display: grid;
    grid-template-columns: 58% 1fr;
    gap: 35px;
    margin-top: 24px;
    cursor: pointer;
    align-items: center;
    .articleTitle {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;

      .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #0668b3;
        border-radius: 50%;
        margin-right: 7px;
      }
    }

    .articleDate {
      flex: 1;
      text-align: right;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
    }
    .onelinestyle {
      background-color: transparent;

      /*1. 超出的部分隐藏 */
      overflow: hidden;
      /*2. 文字用省略号替代超出的部分 */
      text-overflow: ellipsis;
      /* 3. 弹性伸缩盒子模型显示 */
      display: -webkit-box;
      /* 4. 限制在一个块元素显示的文本的行数 */
      -webkit-line-clamp: 1;
      /* 5. 设置或检索伸缩盒对象的子元素的排列方式 */
      -webkit-box-orient: vertical;

      span {
        background-color: transparent !important;
        color: #dddddd !important;
      }
    }
  }
}
.more {
  flex: 1;
  text-align: right;
  padding-right: 15px;
  color: #666;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    color: #0668b3;
  }
}
</style>
