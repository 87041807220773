<template>
  <card title="快速入口">
    <ul class="quick-link">
      <li
        :class="item.code"
        v-for="item of linkList"
        :key="item.code"
        @click="menuHandle(item)"
      >
        {{ item.name }}
      </li>
    </ul>
  </card>
</template>

<script>
import Card from "@/components/Card/index.vue";

const linkList = [
  {
    code: "one",
    name: "贷款超市",
    path: "finance",
  },
  {
    code: "two",
    name: "保险超市",
    path: "financialOther",
  },
  {
    code: "three",
    name: "信用查询",
    path: "financialOther",
  },
  {
    code: "four",
    name: "红名单",
    path: "financialOther",
  },
  {
    code: "five",
    name: "黑名单",
    path: "financialOther",
  },
];
import { mapGetters } from "vuex";
export default {
  components: {
    Card,
  },

  data() {
    return {
      linkList,
    };
  },
  computed: {
    ...mapGetters(["topSelectAreacode"]),
  },
  methods: {
    menuHandle(item) {
      console.log(item,'查看item')
      console.log(typeof this.topSelectAreacode,'查看this.topSelectAreacode')
      if (
        (item.code == "one" &&  typeof this.topSelectAreacode == "undefined" ||this.topSelectAreacode.length < 4)

      ) {
        this.$message.info("请选择所属市，查看对应产品");
      } else {
        this.$router.push({ name: item.path });
      }
    },
  },
};
</script>

<style lang="less" scoped>
ul li {
  list-style: none;
}
.quick-link {
  margin: 0;
  padding: 0;
  display: flex;

  li {
    list-style: none;
    margin: 0 6px;
    padding: 105px 0px 30px 0;

    flex: 1;
    background: #fafafa;
    border: 1px solid #e5e5e5;
    text-align: center;
    font-size: 24px;
    color: #333;
    cursor: pointer;

    &:hover {
      color: #fff;
      transition: 0.3s;
    }

    &.one {
      background: url("../../../assets/icon-f-d-1.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-f-a-1.png") no-repeat center 30px
          #0668b3;
      }
    }

    &.two {
      background: url("../../../assets/icon-f-d-2.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-f-a-2.png") no-repeat center 30px
          #0668b3;
      }
    }

    &.three {
      background: url("../../../assets/icon-f-d-3.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-f-a-3.png") no-repeat center 30px
          #0668b3;
      }
    }
    &.four {
      background: url("../../../assets/icon-f-d-4.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-f-a-4.png") no-repeat center 30px
          #0668b3;
      }
    }
    &.five {
      background: url("../../../assets/icon-f-d-5.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-f-a-5.png") no-repeat center 30px
          #0668b3;
      }
    }

  }
}
</style>
