/**
 * 下载管理接口管理
 */
import service from '../request'

const baseUrl = window.globalUrl.HOME_API;

// 获取文檔列表
export const getFileLists = (params) => {
  // return service.post(`${baseUrl}/file/downZone`, params)
  return service({
    method: 'post',
    url: `${baseUrl}/admin/cms/page`,
    params: params
  })
}

// 下载文件
export const downloadFile = (params) => {
  // return service.post(`${baseUrl}/file/downZone`, params)
  return service({
    method: 'get',
    url: `${baseUrl}/cms/file/${params}`,
    params: params
  })
}


