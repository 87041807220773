// 成果展示相关接口
import service from "../request";

const baseUrl = window.globalUrl.HOME_API;

//首页
//获取主体相关展示信息
export const getSubjectInfo = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/businessType/getTypeInfo`,
    params: params,
  });
};

//累计发放贷款
export const getCumulativeLoanIssuance = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/loan/countLoan`,
    params: params,
  });
};

//累计土地托管
export const getCumulativeLandTrust = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/order/count/countAreaForHome`,
    params: params,
  });
};

// 资源资产流转交易
export const getResourceAssetTransferTransaction = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/trade-website/cqjyService/staticCjmj`,
    data: params,
  });
};

// 资源资产流转金额
export const getResourceAssetTransferAmount = (params) => {
  return service({
    method: "post",
    url: `${baseUrl}/trade-website/cqjyService/staticCjje`,
    data: params,
  });
};

// 后台管理-累计成交面积
export const getSumArea = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjyService/getSumArea`,
    params: params,
  });
};

// 后台管理-累计成交金额
export const getSumCjje = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjyService/getSumCjje`,
    params: params,
  });
};

//生产服务
//累计托管宗数\农户\组织
export const getZSNHZZ = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/land-trustship/orderGroup/dataStatistics`,
    params: params,
  });
};

//累计托管土地面积\交易金额
export const getTDMJJYJE = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/order/count/countAreaForHome`,
    params: params,
  });
};

//新型经营主体\红黑名单主体
export const getSLZT = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/businessType/getTypeInfo`,
    params: params,
  });
};

//生产服务动态
export const getSCFWDT = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/order/pageList`,
    params: params,
  });
};

//新版本接口
// 首页查看托管单数据统计
export const SelectOrderData = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/order/SelectOrderData`,
    params: params,
  });
};
// 首页查看资源资产数据统计
export const SelectXMXXData = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjy/SelectXMXXData`,
    params: params,
  });
};
// 首页查看公司数量数据统计
export const SelectCompanyData = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/admin/company/SelectCompanyData`,
    params: params,
  });
};
// 生产服务查看托管单数据统计
export const SelectOrder = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/order/SelectOrder`,
    params: params,
  });
};

//资源资产服务查看资源资产农民数据统计
export const SelectXMXXFarmerCount = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjy/SelectXMXXFarmerCount`,
    params: params,
  });
};
// 资源资产服务查看资源资产公司数据统计
export const SelectXMXXCompanyCount = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjy/SelectXMXXCompanyCount`,
    params: params,
  });
};
// 资源资产服务查看资源资产承包经营和集体建设数据统计
export const SelectXMXXManageCount = (params) => {
  return service({
    method: "get",
    url: `${baseUrl}/trade-website/cqjy/SelectXMXXManageCount`,
    params: params,
  });
};
